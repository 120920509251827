import React, {Component, useState, useEffect} from 'react';
import { BrowserRouter, Switch, Link, Route } from 'react-router-dom'; 
import Header from '../common/Header'; 
import Footer from '../common/Footer';
import PageHeader from '../components/PageHeader'; 
import withRouter from '../components/withRouter';
import ConfirmContent, {AppWithRouter} from './components/ConfirmContent';

class UserConfirm extends React.Component{

    constructor(){
        super();  
    }
 
    render()
    { 
        return (
            <div>
                <Header/> 
                <PageHeader title="Email Verification" />
                <AppWithRouter token={this.props.params.token}   />  
                <Footer/>
            </div>
        )
    }
}
export default withRouter(UserConfirm);;