import React, {Component , useState } from 'react';
import { BrowserRouter, Switch, Link, Route, useNavigate } from 'react-router-dom'; 
 
import axios from 'axios';   
import {api_base_url, base_url} from '../common/Constants';
import  GeoLocation  from './LocationPicker';
import LocationPicker from './LocationPicker';

 
class CheckoutContent extends React.Component{
    constructor(props){
        super(props);
        
        this.state = {
            cart : [],
            loading : 1,
            billing_firstname:'',
            billing_lastname:'',
            billing_email :'',
            billing_country :'',
            billing_address1 :'',
            billing_address2 :'',
            billing_state :'',
            billing_phone :'', 
            billing_city :'',
            shipping_firstname:'',
            shipping_lastname:'',
            shipping_email :'',
            shipping_country :'',
            shipping_address1 :'',
            shipping_address2 :'',
            shipping_state :'',
            shipping_phone :'', 
            shipping_city :'', 
            order_notes:'',
            errors: {},
            user_id :0,
            user:{},
            ship_to:false
            
        }
        

    }

    componentDidMount()
    {
        localStorage.removeItem('order_id');
        const user_id =  localStorage.getItem('user_id');
        if(user_id !== "" && user_id !== null  &&  user_id !== undefined)
        { 
            this.setState({user_id:user_id}); 
            var data = { user_id : user_id}
            axios.post(api_base_url+"getuser", data).then(response=>{
                this.setState({user:response.data}); 
                this.setState({billing_firstname:response.data.firstname}); 
                this.setState({billing_lastname:response.data.lastname}); 
                this.setState({billing_email:response.data.email});  
                this.setState({billing_phone:response.data.phone});
                this.setState({billing_address1:response.data.address1});
                this.setState({billing_address2:response.data.address2});
                this.setState({billing_city:response.data.city});
                this.setState({billing_state:response.data.state});
                this.setState({billing_country:response.data.country}); 
                this.setState({shipping_firstname:response.data.firstname}); 
                this.setState({shipping_lastname:response.data.lastname}); 
                this.setState({shipping_email:response.data.email});  
                this.setState({shipping_phone:response.data.phone});
                this.setState({shipping_address1:response.data.address1});
                this.setState({shipping_address2:response.data.address2});
                this.setState({shipping_city:response.data.city});
                this.setState({shipping_state:response.data.state});
                this.setState({shipping_country:response.data.country});
                const order_session_id =  localStorage.getItem('order_session_id');
                var data = { order_session_id : order_session_id}
                axios.post("https://alhuda.integratesolu.com/public/api/cart", data).then(response=>{
                    this.setState({cart:response.data}); 
                    const order_id =  localStorage.getItem('order_id');
                    if(order_id !== "" && order_id !== null  &&  order_id !== undefined)
                    { 
                   
                       var data = { order_id : order_id}
                        axios.post("https://alhuda.integratesolu.com/public/api/get_order_info", data).then(response=>{
                            this.setState({billing_firstname:response.data.billing_firstname}); 
                            this.setState({billing_lastname:response.data.billing_lastname}); 
                            this.setState({billing_email:response.data.billing_email}); 
                            this.setState({billing_address1:response.data.billing_address1}); 
                            this.setState({billing_address2:response.data.billing_address2}); 
                            this.setState({billing_phone:response.data.billing_phone}); 
                            this.setState({billing_city:response.data.billing_city}); 
                            this.setState({billing_country:response.data.billing_country}); 
                            this.setState({billing_state:response.data.billing_state}); 
                            this.setState({shipping_firstname:response.data.shipping_firstname}); 
                            this.setState({shipping_lastname:response.data.shipping_lastname}); 
                            this.setState({shipping_email:response.data.shipping_email}); 
                            this.setState({shipping_address1:response.data.shipping_address1}); 
                            this.setState({shipping_address2:response.data.shipping_address2}); 
                            this.setState({shipping_phone:response.data.shipping_phone}); 
                            this.setState({shipping_city:response.data.shipping_city}); 
                            this.setState({shipping_country:response.data.shipping_country}); 
                            this.setState({shipping_state:response.data.shipping_state});    
                            this.setState({order_notes:response.data.user_comments});  
                            this.setState({loading:0}); 
            
                        });
                     }
                     else{
                        this.setState({loading:0});
                     }
                });
            });
        }
       else{
        const order_session_id =  localStorage.getItem('order_session_id');
        var data = { order_session_id : order_session_id}
        axios.post("https://alhuda.integratesolu.com/public/api/cart", data).then(response=>{
            this.setState({cart:response.data}); 
            const order_id =  localStorage.getItem('order_id');
            if(order_id !== "" && order_id !== null  &&  order_id !== undefined)
            { 
           
               var data = { order_id : order_id}
                axios.post("https://alhuda.integratesolu.com/public/api/get_order_info", data).then(response=>{
                    this.setState({billing_firstname:response.data.billing_firstname}); 
                    this.setState({billing_lastname:response.data.billing_lastname}); 
                    this.setState({billing_email:response.data.billing_email}); 
                    this.setState({billing_address1:response.data.billing_address1}); 
                    this.setState({billing_address2:response.data.billing_address2}); 
                    this.setState({billing_phone:response.data.billing_phone}); 
                    this.setState({billing_city:response.data.billing_city}); 
                    this.setState({billing_country:response.data.billing_country}); 
                    this.setState({billing_state:response.data.billing_state}); 
                    this.setState({shipping_firstname:response.data.shipping_firstname}); 
                    this.setState({shipping_lastname:response.data.shipping_lastname}); 
                    this.setState({shipping_email:response.data.shipping_email}); 
                    this.setState({shipping_address1:response.data.shipping_address1}); 
                    this.setState({shipping_address2:response.data.shipping_address2}); 
                    this.setState({shipping_phone:response.data.shipping_phone}); 
                    this.setState({shipping_city:response.data.shipping_city}); 
                    this.setState({shipping_country:response.data.shipping_country}); 
                    this.setState({shipping_state:response.data.shipping_state});       
                    this.setState({order_notes:response.data.user_comments});  
                    this.setState({loading:0}); 
    
                });
             }
             else{
                this.setState({loading:0});
             }
        });
       }
        
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const errors = {};
        if (!this.state.billing_firstname) {
            errors.billing_firstname = 'Name is required';
          }
          if (!this.state.billing_country) {
            errors.billing_country = 'Country is required';
          }
          if (!this.state.billing_address1) {
            errors.billing_address1 = 'Address line 1 is required';
          }
          if (!this.state.billing_state) {
            errors.billing_state = 'State is required';
          }
          if (!this.state.billing_city) {
            errors.billing_city = 'City is required';
          } 
          if (!this.state.billing_phone ) {
            errors.billing_phone = 'Phone is required';
          }
          if (!this.state.billing_email || !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.state.billing_email)) {
          errors.billing_email = 'Invalid email';
        }
    
        if (Object.keys(errors).length > 0) {
          this.setState({ errors });
        } else {
          
            const order_session_id =  localStorage.getItem('order_session_id');
            let orderid =  localStorage.getItem('order_id');

            if(orderid === "" ||orderid === null || orderid === undefined)
            {
                orderid = 0;
            }

            var data = { order_session_id : order_session_id, data:this.state, order_id : orderid }
            axios.post("https://alhuda.integratesolu.com/public/api/checkout", data).then(response=>{

                localStorage.setItem('order_id',response.data.id) ;
                this.props.navigate('/confirm');

            });
            

        }
      };
    
      handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });

        if(!this.state.ship_to)
        {
            var res = name.split('_');
             this.setState( {['shipping_'+res[1]]: value});
        }
      };
      handleShipping = (event) => {
         
        if(event.target.checked)
        { 
            this.setState({ship_to:true });
        }
        else{
             this.setState({ship_to:false });
        }
      };

    render()
    { 
        if (this.state.loading == 1) {
            return <div>Loading...</div>;
          }

         
    return (

            <div>
        <section className="checkout-page section-space">
            <div className="container">
                <div className="row gutter-y-30">
                    <div className="col-xl-8 col-lg-7">
                        <div className="checkout-page__billing-address">
                            <h2 className="checkout-page__billing-address__title checkout-page__title">Billing Details</h2>
                            <form className="checkout-page__form row gutter-y-16">
                            <div className="col-xl-12">
                                    <div className="checkout-page__radio-box--left checkout-page__radio-box">
                                        <div className="checkout-page__input-item custom-radio" id={this.state.user_id == 0 ? "dblock" : "dnone"}>
                                            <input type="radio" id="account" name="checkout-info" className="custom-radio__input" checked />
                                            <Link  to={base_url+"user/login"}><label for="account" className="custom-radio__title">Sign In to your Account</label></Link>
                                        </div> 
                                    </div>
                                </div>

                            <div className="col-xl-6">
                                    <div className="checkout-page__input-box">
                                        <label for="first-name">First Name *</label>
                                        <input type="text" name="billing_firstname" id="first-name"  
            required="" onChange={this.handleChange} value={this.state.billing_firstname} />
           {this.state.errors.billing_firstname && <div>{this.state.errors.billing_firstname}</div>}
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                <div className="checkout-page__input-box">
                                        <label for="last-name">Last Name</label>
                                        <input type="text" name="billing_lastname" id="last-name"  
            required="" onChange={this.handleChange} value={this.state.billing_lastname} />
           {this.state.errors.billing_lastname && <div>{this.state.errors.billing_lastname}</div>}
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="checkout-page__input-box">
                                        <label for="email">Email Address *</label>
                                        <input type="email" name="billing_email" id="email"  
            required=""  onChange={this.handleChange} value={this.state.billing_email} />
           {this.state.errors.billing_email && <div>{this.state.errors.billing_email}</div>}
          
                                    </div>
                                </div>
                                
                                <div className="col-xl-12">
                                    <div className="checkout-page__input-box">
                                        <label>Country / Region *</label>
                                        <select className="selectpicker" name="billing_country" aria-label="Default select example" onChange={this.handleChange}>
                                            <option selected="">Select a country</option>
                                            <option value="uae" selected={this.state.billing_country == "uae" ? 'selected' : ''} >UAE</option> 
                                        </select>
                                        {this.state.errors.billing_country && <div>{this.state.errors.billing_name}</div>}
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="checkout-page__input-box checkout-page__input-box--address">
                                        <label for="address">Street Address *</label>
                                        <input type="text" name="billing_address1"  value={this.state.billing_address1}  id="address" onChange={this.handleChange} placeholder="" required="" />
                                        <input type="text" name="billing_address2"  value={this.state.billing_address2}  required="" onChange={this.handleChange}  />
                                        {this.state.errors.billing_address1 && <div>{this.state.errors.billing_address1}</div>}
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="checkout-page__input-box">
                                        <label for="town-city">Town / City *</label>
                                        <input type="text" name="billing_city"  value={this.state.billing_city} id="town-city" onChange={this.handleChange} required="" />
                                        {this.state.errors.billing_city && <div>{this.state.errors.billing_city}</div>}
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="checkout-page__input-box">
                                        <label>State *</label>
                                        <select className="selectpicker" name='billing_state' aria-label="Default select example" onChange={this.handleChange}>
                                         
                                        <option selected="">Select a State</option>
                                                <option value="Abu Dhabi"  selected={this.state.billing_state == "Abu Dhabi" ? 'selected' : ''}>Abu Dhabi</option>
                                                
 
                                        </select>
                                        {this.state.errors.billing_state && <div>{this.state.errors.billing_state}</div>}
                                    </div>
                                </div> 
                                <div className="col-xl-12">
                                    <div className="checkout-page__input-box">
                                        <label for="phone">Phone *</label>
                                        <input type="tel" name="billing_phone" id="phone"  value={this.state.billing_phone} pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" required="" onChange={this.handleChange} />
                                        {this.state.errors.billing_phone && <div>{this.state.errors.billing_phone}</div>}
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="checkout-page__radio-box--left checkout-page__radio-box">
                                         
                                        <div className="checkout-page__input-item custom-radio">
                                            <input type="checkbox" id="ship" name="checkout-info" onClick={this.handleShipping} checked={this.state.ship_to} className="custom-radio__input"  />
                                            <label for="ship" className="custom-radio__title">Ship To A Different Address?</label>
                                        </div>
                                    </div>
                                </div>  
                                
                            </form>
                        </div>
                        <div className="checkout-page__billing-address" id={this.state.ship_to ? "dblock" : "dnone"}>
                            <h2 className="checkout-page__billing-address__title checkout-page__title">Shipping Details</h2>
                            <form className="checkout-page__form row gutter-y-16">
                            <div className="col-xl-12">
                                    <div className="checkout-page__radio-box--left checkout-page__radio-box">
                                        <div className="checkout-page__input-item custom-radio" id={this.state.user_id == 0 ? "dblock" : "dnone"}>
                                            <input type="radio" id="account" name="checkout-info" className="custom-radio__input" checked />
                                            <Link  to={base_url+"user/login"}><label for="account" className="custom-radio__title">Sign In to your Account</label></Link>
                                        </div> 
                                    </div>
                                </div>

                            <div className="col-xl-6">
                                    <div className="checkout-page__input-box">
                                        <label for="first-name">First Name *</label>
                                        <input type="text" name="shipping_firstname" id="first-name"  
            required="" onChange={this.handleChange} value={this.state.shipping_firstname} />
           {this.state.errors.shipping_firstname && <div>{this.state.errors.shipping_firstname}</div>}
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                <div className="checkout-page__input-box">
                                        <label for="last-name">Last Name</label>
                                        <input type="text" name="shipping_lastname" id="last-name"  
            required="" onChange={this.handleChange} value={this.state.shipping_lastname} />
           {this.state.errors.shipping_lastname && <div>{this.state.errors.shipping_lastname}</div>}
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="checkout-page__input-box">
                                        <label for="email">Email Address *</label>
                                        <input type="email" name="shipping_email" id="email"  
            required=""  onChange={this.handleChange} value={this.state.shipping_email} />
           {this.state.errors.shipping_email && <div>{this.state.errors.shipping_email}</div>}
          
                                    </div>
                                </div>
                                
                                <div className="col-xl-12">
                                    <div className="checkout-page__input-box">
                                        <label>Country / Region *</label>
                                        <select className="selectpicker" name="shipping_country" aria-label="Default select example" onChange={this.handleChange}>
                                            <option selected="">Select a country</option>
                                            <option value="uae" selected={this.state.shipping_country == "uae" ? 'selected' : ''} >UAE</option> 
                                        </select>
                                        {this.state.errors.shipping_country && <div>{this.state.errors.shipping_name}</div>}
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="checkout-page__input-box checkout-page__input-box--address">
                                        <label for="address">Street Address *</label>
                                        <input type="text" name="shipping_address1"  value={this.state.shipping_address1}  id="address" onChange={this.handleChange} placeholder="" required="" />
                                        <input type="text" name="shipping_address2"  value={this.state.shipping_address2}  required="" onChange={this.handleChange}  />
                                        {this.state.errors.shipping_address1 && <div>{this.state.errors.shipping_address1}</div>}
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="checkout-page__input-box">
                                        <label for="town-city">Town / City *</label>
                                        <input type="text" name="shipping_city"  value={this.state.shipping_city} id="town-city" onChange={this.handleChange} required="" />
                                        {this.state.errors.shipping_city && <div>{this.state.errors.shipping_city}</div>}
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="checkout-page__input-box">
                                        <label>State *</label>
                                        <select className="selectpicker" name='shipping_state' aria-label="Default select example" onChange={this.handleChange}>
                                         
                                        <option selected="">Select a State</option>
                                                <option value="Abu Dhabi"  selected={this.state.shipping_state == "Abu Dhabi" ? 'selected' : ''}>Abu Dhabi</option>
                                                
 
                                        </select>
                                        {this.state.errors.shipping_state && <div>{this.state.errors.shipping_state}</div>}
                                    </div>
                                </div> 
                                <div className="col-xl-12">
                                    <div className="checkout-page__input-box">
                                        <label for="phone">Phone *</label>
                                        <input type="tel" name="shipping_phone" id="phone"  value={this.state.shipping_phone} pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" required="" onChange={this.handleChange} />
                                        {this.state.errors.shipping_phone && <div>{this.state.errors.shipping_phone}</div>}
                                    </div>
                                </div>
  
                            </form>
                        </div>
                        <div className="checkout-page__billing-address">
                             
                                <div className="col-xl-12">
                                    <div className="checkout-page__input-box checkout-page__input-box--message">
                                        <label for="order-notes">Order Notes (Optional)</label>
                                        <textarea name="order_notes"  onChange={this.handleChange} value={this.state.order_notes} id="order-notes" cols="30" rows="10" placeholder="Note About Your Order . . ."></textarea>
                                    </div>
                                </div>
                           
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-5">
                        <div className="checkout-page__your-order">
                            <h2 className="checkout-page__your-order__title checkout-page__title">Your order</h2>
                            <table className="checkout-page__order-table">
                                <thead className="checkout-page__order-table__heade">
                                    <tr>
                                        <th>Product</th>
                                        <th className="right">Subtotal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.cart.cart.map((item, index) => (
                                    <tr>
                                        <td className="pro__title">{item.title}</td>
                                        <td className="pro__price">{item.total_price}</td>
                                    </tr>
                                    ))} 
                                    <tr>
                                        <td className="pro__sub-title">Subtotal</td>
                                        <td className="pro__sub-price">{this.state.cart.subtotal}</td>
                                    </tr>
									<tr>
                                        <td className="pro__sub-title">VAT 5%</td>
                                        <td className="pro__sub-price">{this.state.cart.vat}</td>
                                    </tr>
									<tr>
                                        <td className="pro__sub-title">Delivery Charges</td>
                                        <td className="pro__sub-price">{this.state.cart.delivery_charges}</td>
                                    </tr>
									<tr>
                                        <td className="pro__sub-title">Total</td>
                                        <td className="pro__sub-price">{this.state.cart.grand_total}</td>
                                    </tr>                                 
                                    <tr>
                                        <td colspan="2">
                                            <p className="checkout-page__order-text">Your personal data will be used to process your
                                                order, support your experience throughout this website.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <Link onClick={this.handleSubmit} className="checkout-page__order-btn boskery-btn">
                                                <span className="boskery-btn__hover"></span>
                                                <span className="boskery-btn__hover"></span>
                                                <span className="boskery-btn__hover"></span>
                                                <span className="boskery-btn__hover"></span>
                                                <span className="boskery-btn__hover"></span>
                                                <span className="boskery-btn__hover"></span>
                                                <span className="boskery-btn__text">Confirm Order</span>
                                                <i className="icon-meat-3"></i>
                                            </Link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section> 

<br /><br />
            </div>
        
    );
}

}

export function AppWithRouter(props){
    const navigate = useNavigate();
    return  (<CheckoutContent navigate={navigate} ></CheckoutContent>)
}

export default CheckoutContent;