import React, {Component} from 'react';
import { BrowserRouter, Switch, Link, Route } from 'react-router-dom'; 
 
import axios from 'axios'; 

 
class BlogsHome extends React.Component{

    constructor(){
        super();

        
        this.state = {
            blogs : [],
            loading : 1
        }
        

    }

    componentDidMount()
    {
        axios.get("https://alhuda.integratesolu.com/public/api/blogs").then(response=>{
            this.setState({blogs:response.data}); 
            this.setState({loading:0})
        });
    }


    render()
    { 
        if (this.state.loading == 1) {
            return <div>Loading...</div>;
          }
    return (
        <section className="blog-one section-space-two" id="shop" style={{backgroundImage: `url(assets/images/shapes/why-choose-bg-1-1.png)`}} >
        <div className="container">
            <div className="sec-title sec-title--center">

                <img src="assets/images/shapes/sec-title-s-1.png" alt="latest news" className="sec-title__img" />


                <h6 className="sec-title__tagline">latest news</h6> 

                <h2 className="sec-title__title">news / blog </h2> 

            <div className="row gutter-y-30">

             
                {this.state.blogs.slice(0, 3).map((blog,key)=>{
 
                    return <div className="col-md-6 col-lg-4">
                    <div className="blog-card wow fadeInUp" data-wow-duration='1500ms' data-wow-delay='000ms'>
                        <div className="blog-card__content">
                            <div className="blog-card__top">
                                <div className="blog-card__date"><span>{blog.created_date}</span> <span>{blog.created_month}</span></div> 
                                <ul className="list-unstyled blog-card__meta">
                                    <li><a href="#">
                                            <span className="icon-user"></span>
                                            by Admin</a></li>
                                    <li><a href="#">
                                            <span className="icon-chat"></span>
                                            2 Comments</a></li>
                                </ul> 
                            </div> 
                            <div className="blog-card__image">
                                <img src={blog.image} alt={blog.title} />
                                <a href="blog-details-right.html" className="blog-card__hover">
                                    <span className="sr-only">{blog.title}</span>
                                    <div className="blog-card__hover__box blog-card__hover__box--1"></div>
                                    <div className="blog-card__hover__box blog-card__hover__box--2"></div>
                                    <div className="blog-card__hover__box blog-card__hover__box--3"></div>
                                    <div className="blog-card__hover__box blog-card__hover__box--4"></div>
                                </a>
                            </div> 
                            <h3 className="blog-card__title"><Link to={`/blogs/${blog.slug}`}>{blog.subtitle}</Link></h3>
                        </div> 
                        <Link to={`/blogs/${blog.slug}`} className="blog-card__link">
                            Read more
                            <span className="icon-right"></span>
                        </Link> 
                    </div> 
                </div>  
                 })}
   

                
            </div>
            </div>
        </div> 
    </section> 
    );
}

}

export default BlogsHome;