 
import React, {Component} from 'react';
import { BrowserRouter, Switch, Link, Route, useNavigate } from 'react-router-dom'; 
 
import axios from 'axios';   
import {api_base_url, base_url} from '../../common/Constants';
 
 
class ConfirmContent extends React.Component{

    constructor(props){
        super(props); 
        this.state = { 
            loading:0,
            
        }
        

    }

    componentDidMount()
    { 
        var data = {  token : this.props.token }
        axios.post(api_base_url+"user/confirmemail", data).then(response=>{
            
                this.setState({loading:1});
             
        });
        
    };
 

    render()
    { 
        if (this.state.loading == 1) {
            return <div>Loading...</div>;
          }
    return (
        <section className="login-page">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Your email address has been successfully verified. You can now login using your credentials.</p>

                        <Link to={base_url+"user/login"}> <button type="button"  className="boskery-btn login-page__form__btn">Login</button></Link>
                    </div>
                </div>
            </div>
        </section>
    )
    }

}


export function AppWithRouter(props){
    const navigate = useNavigate();
    return  (<ConfirmContent navigate={navigate} token={this.props.token}  ></ConfirmContent>)
}


export default ConfirmContent;