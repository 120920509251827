import React, {Component, useState, useEffect} from 'react';
import { BrowserRouter, Switch, Link, Route } from 'react-router-dom';
import ProductsContent from '../components/ProductsContent'; 
import axios from 'axios'; 
import Header from '../common/Header'; 
import Footer from '../common/Footer';
import PageHeader from '../components/PageHeader'; 
import withRouter from '../components/withRouter';
import {api_base_url, base_url} from '../common/Constants'; 

 

class Products extends React.Component{

    constructor(props){
        super(props);

        
        this.state = {
            cartcount : 0
        }

    }

    componentDidMount()
    {  
        const order_session_id =  localStorage.getItem('order_session_id');
        var data = { order_session_id : order_session_id}
        axios.post(api_base_url+"cart", data).then(response=>{ 
            this.setState({cartcount:response.data.cart.length});  
        });
        
    }
 
    render()
    { 
        return (
            <div>
               
                    <Header cartpage={true} cartcount={this.state.cartcount} updateSharedState={this.updateSharedState} /> 
                    <PageHeader title="Shop" slug={this.props.params.id} />
                    <ProductsContent slug={this.props.params.id}  />  
                    <Footer/>
                 
        
        </div>
       
        )
    }

}
export default withRouter(Products);;