import React, {Component} from 'react';
import { BrowserRouter, Switch, Link, Route } from 'react-router-dom'; 
 
import axios from 'axios'; 

 
class PopularProducts extends React.Component{

    constructor(){
        super();

        
        this.state = {
            products : [],
            loading : 1
        }
        

    }

    componentDidMount()
    {
        axios.get("https://alhuda.integratesolu.com/public/api/products/popular").then(response=>{
            this.setState({products:response.data}); 
            this.setState({loading:0});

        });
    }


    render()
    { 
        if (this.state.loading == 1) {
            return <div>Loading...</div>;
          }
    return (
        <section className={ this.state.products.length == 0 ? "product-one section-space-two dnone" :  "product-one section-space-two" }  id="shop" style={{backgroundImage: `url(assets/images/shapes/why-choose-bg-1-1.png)`}}>
            <div className="container">
                <div className="sec-title sec-title--center">

                    <img src="assets/images/shapes/sec-title-s-1.png" alt="featured products" className="sec-title__img" />


                    <h6 className="sec-title__tagline">featured products</h6> 

                    <h2 className="sec-title__title">popular products</h2> 
                </div> 
                <div className="row gutter-y-30">

               
                {this.state.products.slice(0, 8).map((prod,key)=>{
 
                    return <div className="col-xl-3 col-lg-4 col-sm-6">
                    <div className="product__item wow fadeInUp" data-wow-duration='1500ms' data-wow-delay='00ms'>
                        <div className="product__item__image">
                            <img src={prod.image[0]} alt={prod.title} />
                        </div> 
                        <div className="product__item__content">
                            
                            <h4 className="product__item__title"><Link to={`/products/${prod.slug}`} >{prod.title}</Link></h4> 
                            <div className="product__item__price price-strike"  id={prod.discount > 0 ? "view_old_price" : "hide_old_price"}>{prod.old_price}</div> 
                            <div className="product__item__price">{prod.price}</div> 
                            <Link to={`/products/${prod.slug}`} className="boskery-btn product__item__link">
                                <span className="boskery-btn__hover"></span>
                                <span className="boskery-btn__hover"></span>
                                <span className="boskery-btn__hover"></span>
                                <span className="boskery-btn__hover"></span>
                                <span className="boskery-btn__hover"></span>
                                <span className="boskery-btn__hover"></span>
                                <span className="boskery-btn__text">View More</span>
                                <i className="icon-meat-3"></i>
                            </Link>
                        </div> 
                    </div> 
                </div>  
                 })}
    
                     
                </div> 
            </div> 
            
        </section> 
    );
}

}

export default PopularProducts;