 
import React, {Component} from 'react';
import { BrowserRouter, Switch, Link, Route, useNavigate } from 'react-router-dom'; 
 
import axios from 'axios';   
import {api_base_url, base_url} from '../../common/Constants';
 
 
class LoginContent extends React.Component{

    constructor(props){
        super(props); 
        this.state = { 
            username:'',
            password:'', 
            errors:{},
            fopen:false,
            message:'',
            reg_error:false,
            err_message:"",
            errors:{}
        }
        

    }

    componentDidMount()
    {
        
      };

      handleSubmit = (event) =>{
        
        const errors = {};
        if (!this.state.username ) {
            errors.username = 'Username or Email address is required';
          }
          else if (this.state.username.length < 6 ) {
            errors.username = 'Username should have atleast 6 letters';
          }
          if (!this.state.password ) {
            errors.password = 'Password is required';
          }
          else if (this.state.password.length < 6) {
            errors.password = 'Password should have atleast 6 letters';
          }
          //if (!this.state.terms ) {
            //errors.terms = 'Please check this to continue';
          //}
    
        if (Object.keys(errors).length > 0) {
          this.setState({ errors });
        } else {
          
            this.setState({ errors });  
            var data = {  data:this.state  }
            axios.post(api_base_url+"user/login", data).then(response=>{

                if(response.data.error)
                {
                    this.setState({reg_error:true});
                    this.setState({err_message: response.data.message});
                    
                }
                else{
                    this.setState({reg_error:false});
                    localStorage.setItem('user_id',response.data.id) ;
                    //this.setState({fopen:true});
                    this.props.navigate('/user/profile');
                
                }
               
                //this.setState({message:" Thank you for registering with us. A mail has been sent to your email address. Please follow the instructions in the mail to verify your"});
                //localStorage.setItem('user_id',response.data.id) ;
                //window.location.href = '/confirm-order';

            }).catch((error) => { 
                  console.log('Conflict error:', error.response);
               
            });

        }
      }
      handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
      };

    render()
    { 
        if (this.state.loading == 1) {
            return <div>Loading...</div>;
          }
    return (
        <section className="login-page">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="login-page__inner">
                            <div className="login-page__image wow fadeInLeft animated fadeLeft" data-wow-duration="1500ms" >
                                <img src="../assets/images/login-bg.png" alt="login" />
                            </div> 
                            <div className="login-page__wrap login-page__main-tab-box tabs-box wow fadeInRight animated fadeRight" data-wow-duration="1500ms" >
                                <div className="login-page__wrap__bg"  style={{backgroundImage: `url(assets/images/shapes/login-bg-1.png)`}}  ></div> 
                                <div className="login-page__wrap__top">
                                    <div className="login-page__wrap__content">
                                        <h3 className="login-page__title">welcome</h3>
                                    </div> 
                                    <ul className="tab-buttons">
                                        <li data-tab="#login" className="tab-btn boskery-btn active-btn">
                                            <span className="boskery-btn__hover"></span>
                                            <span className="boskery-btn__hover"></span>
                                            <span className="boskery-btn__hover"></span>
                                            <span className="boskery-btn__hover"></span>
                                            <span className="boskery-btn__hover"></span>
                                            <span className="boskery-btn__hover"></span>
                                            <span className="boskery-btn__text">log in</span>
                                        </li> 
                                    </ul>
                                </div>
                                <div className="tabs-content">
                                    <div className="tab fadeInUp animated active-tab dblock" data-wow-delay="200ms" id="login" >
                                        <span className="login-page__tab-title">sign in your alhuda account</span>
                                        <form className="login-page__form">
                                        <div className="login-page__form__input-box">
                                                <input type="text" placeholder="Username/ Email Address"  name="username" id="username"  
                                                    required="" onChange={this.handleChange} value={this.state.username} />
                                                {this.state.errors.username && <label className="error">{this.state.errors.username}</label>}
                                                <span className="icon-padlock"></span>
                                            </div> 
                                            
                                            <div className="login-page__form__input-box">
                                                <input type="password" placeholder="Password" className="login-page__password"  name="password" id="password"  
                                                    required="" onChange={this.handleChange} value={this.state.password} />
                                                {this.state.errors.password && <label className="error">{this.state.errors.password}</label>}
                                                <span className="icon-padlock"></span>
                                            </div> 
                                            <div className="login-page__form__input-box login-page__form__input-box--forgot">
                                                <a href="#" className="login-page__form__forgot">forgot password?</a> 
                                            </div>
                                            <div className="login-page__form__input-box login-page__form__input-box--button">
                                                <button type="button" onClick={this.handleSubmit} className="boskery-btn login-page__form__btn">Login</button>
                                            </div> 
                                            
                                            <div className="login-page__form__input-box login-page__form__input-box--checkbox">
                                                <div className="login-page__form__checked-box">
                                                    <input type="checkbox" name="remember-policy" id="remember-policy" />
                                                    <label for="remember-policy"><span></span>remember me</label>
                                                </div>
                                            </div> 

                                            <div className='alert alert-success '  id={this.state.fopen ? "dblock" : "dnone"}>{this.state.message}</div>
                        
                        <div className='alert alert-warning '  id={this.state.reg_error ? "dblock" : "dnone"}>{this.state.err_message}</div>
    
                                        </form> 
                                        <div className="login-page__signin">
                                            <h4 className="login-page__signin__title">don’t have an account? <Link to={base_url+"user/register"}>register</Link></h4> 
                                           
                                        </div> 
                                    </div> 
 
                                </div> 
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </section>
    )
    }

}


export function AppWithRouter(props){
    const navigate = useNavigate();
    return  (<LoginContent navigate={navigate}  ></LoginContent>)
}


export default LoginContent;