 
import React, {Component} from 'react';
import { BrowserRouter, Switch, Link, Route, useNavigate } from 'react-router-dom'; 
 
import axios from 'axios';   
import {api_base_url, base_url} from '../../common/Constants';
import LeftMenu, {AppWithRouter} from './LeftMenu';
 
 
class OrdersContent extends React.Component{

    constructor(props){
        super(props);

        
        this.state = { 
            orders:[],
            i:0,
            loading:1
        }
        

    }

    componentDidMount()
    {
        const user_id =  localStorage.getItem('user_id');
        
        if(user_id !== "" && user_id !== null  &&  user_id !== undefined)
        { 
            var data = { user_id : user_id}
            axios.post(api_base_url+"user/orders", data).then(response=>{
                this.setState({orders:response.data});  
                this.setState({loading:0});  

            });
        }
        else
        {
            this.props.navigate('user/login');
        }

    }
  

    render()
    { 
        if (this.state.loading == 1) {
            return <div>Loading...</div>;
          }
    return (
        <section className="profile-page">
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                      <AppWithRouter selected="orders" />
                    </div>
                    <div className="col-md-9">
                        <div className='orders-data'  id={this.state.orders.loading == 1 ? "dnone" : "dblock"}>
                            <table>
                                <tr>
                                    <td>Sl No</td>
                                    <td>Order No</td>
                                    <td>Order Date</td>
                                    <td>Order Status</td>
                                    <td>Total</td>
                                    <td></td>  
                                </tr>

                                {this.state.orders.map((item, index) => (
                                <tr>
                                    <td>{index+1}</td>
                                    <td>{item.order_no}</td>
                                    <td>{item.order_date}</td>
                                    <td>{item.order_status}</td>
                                    <td>{item.order_total}</td>
                                    <td><Link to={base_url+"user/view-order/"+item.order_no} className='view'><i class="icon-search"></i> View</Link></td>  
                                </tr>  

                                ))}
                                <tr>
                                    <td colSpan={6} className={ this.state.orders.length > 0 ? "dnone" : "dblock"} id="no-orders">No Orders Yet</td> 
                                </tr>  

                            </table>
                             
                        </div>
                        <div className='loading '  id={this.state.loading == 0 ? "dnone" : "dblock"}> <img src={base_url+"assets/images/loader.gif"} alt="Alhuda" width="150" /></div>
                    
                    </div>
                </div>
            </div>
        </section>
    )
    }

}

export function AppWithNavigate(props){
    const navigate = useNavigate();
    return  (<OrdersContent navigate={navigate} ></OrdersContent>)
}


export default OrdersContent;