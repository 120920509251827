import React, {Component} from 'react';
import { BrowserRouter, Switch, Link, Route } from 'react-router-dom'; 
 
import axios from 'axios'; 
import {api_base_url, base_url} from '../common/Constants';

 
class ContactContent extends React.Component{

    constructor(){
        super();

        
        this.state = { 
            loading : 1,
            settings:{},
            name:'',
            email:'',
            message:'',
            errors:{},
            fopen:false,
            success_message:'',
            reg_error:false,
            err_message:""
            
        }
        

    }

    handleSubmit = (event) => {
        event.preventDefault();
        const errors = {};
        if (!this.state.name) {
            errors.name = 'Name is required';
          }  
          if (!this.state.email || !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.state.email)) {
          errors.email = 'Invalid email';
        }
        
        if (!this.state.message) {
            errors.message = 'Message is required';
          } 
        if (Object.keys(errors).length > 0) {
          this.setState({ errors });
        } else {
          
             
            var data = {  name:this.state.name, 'email' :this.state.email, 'message':this.state.message  }
            axios.post(api_base_url+"send_contact", data).then(response=>{

                if(response.data.error)
                {
                    this.setState({reg_error:true});
                    this.setState({fopen:false});
                    this.setState({err_message: response.data.message});  
                }
                else{
                    
                    this.setState({fopen:true});
                    this.setState({reg_error:false});
                    this.setState({success_message:" Enquiry Successfully Sent."});
                    this.setState({name:''});
                    this.setState({email:''});
                    this.setState({message:''});
                }
                
            }).catch((error) => { 
                  console.log('Conflict error:', error.response);
               
            });

        }
      };
    
    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
      };

    componentDidMount()
    {
        axios.get(api_base_url+"settings").then(response=>{
            this.setState({settings:response.data}) ;
        });
    }


    render()
    { 
        
    return (
         
           <div>

<section className="contact-page section-space-top">
            <div className="container">
                <div className="contact-page__wrapper">
                    <div className="contact-page__content">
                        <div className="contact-page__sec-title">
                            <h2 className="contact-page__title">Get In Touch</h2> 
                            <p className="contact-page__text">{this.state.settings.site_contact_text}</p> 
                        </div> 
                        <form action="#" className="contact-page__form contact-form-validated form-one">
                            <div className="form-one__group">
                                <div className="form-one__control wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="00ms">
                                    <input type="text" name="name" placeholder="Your Name"  id="name"  
                                        required="" onChange={this.handleChange} value={this.state.name} />
                                    {this.state.errors.name && <label className="error">{this.state.errors.name}</label>}
                                </div> 
                                <div className="form-one__control wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="50ms">
                                    <input type="email" name="email" placeholder="Your Email" id="email"  
                                        required="" onChange={this.handleChange} value={this.state.email} />
                                    {this.state.errors.email && <label className="error">{this.state.errors.email}</label>}
                                </div> 
                                <div className="form-one__control form-one__control--full wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="100ms">
                                    <textarea name="message" placeholder="Write Message . ."  id="message"  
                                        required="" onChange={this.handleChange} value={this.state.message}></textarea>
                                    
                                    {this.state.errors.message && <label className="error">{this.state.errors.message}</label>}
                                </div> 
                                <div className="form-one__control form-one__control--full wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="150ms">
                                    <button type="button" className="boskery-btn"  onClick={this.handleSubmit} >
                                        <span className="boskery-btn__hover"></span>
                                        <span className="boskery-btn__hover"></span>
                                        <span className="boskery-btn__hover"></span>
                                        <span className="boskery-btn__hover"></span>
                                        <span className="boskery-btn__hover"></span>
                                        <span className="boskery-btn__hover"></span>
                                        <span className="boskery-btn__text">send message</span>
                                        <i className="icon-meat-3"></i>
                                    </button>
                                </div> 

                                
                                <div className='alert alert-success '  id={this.state.fopen ? "dblock" : "dnone"}>{this.state.success_message}</div>
                        
                                <div className='alert alert-warning '  id={this.state.reg_error ? "dblock" : "dnone"}>{this.state.err_message}</div>
            
                            </div> 
                        </form> 
                        <div className="result"></div> 
                    </div> 
                    <div className="contact-page__image wow fadeInRight" data-wow-duration="1500ms">
                        <img src="assets/images/resources/contact-1-1.jpg" alt="contact" />
                    </div>
                </div> 
            </div> 
        </section> 

        <section className="contact-info section-space-bottom">
            <div className="contact-info__bg boskery-jarallax" data-jarallax data-speed="0.3" style={{backgroundImage: `url(assets/images/backgrounds/contact-bg.jpg)`}}></div> 
            <div className="container">
                <div className="row gutter-y-30 contact-row">
                    <div className="col-lg-6 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="00ms">
                        <div className="contact-info__contact">
                            <div className="contact-info__contact__bg" style={{backgroundImage: `url(assets/images/shapes/contact-bg-1-1.png)`}}></div> 
                            <div className="contact-info__contact__content">
                                <div className="contact-info__top">
                                    <h4 className="contact-info__title">ALHUDA BUTCHERY (KHALIDIYA)</h4> 
                                    
                                </div> 
                                <div className="contact-info__contact-list">
                                    <div className="contact-info__contact-list__item">
                                        <span className="contact-info__contact-list__icon icon-phone-call"></span> 
                                        <div className="contact-info__contact-list__content">
                                            <span className="contact-info__contact-list__title">call now</span> 
                                            <Link href={"tel: "+this.state.settings.contact_land_number1} className="contact-info__contact-list__link"> {this.state.settings.contact_land_number1}</Link>,
                                            <Link href={"tel: "+this.state.settings.contact_mobile_number1} className="contact-info__contact-list__link"> {this.state.settings.contact_mobile_number1}</Link>
                                             
                                        </div> 
                                    </div> 
                                    <div className="contact-info__contact-list__item">
                                        <span className="contact-info__contact-list__icon icon-paper-plane"></span> 
                                        <div className="contact-info__contact-list__content">
                                            <span className="contact-info__contact-list__title">email</span> 
                                            <Link href={"mailto: "+this.state.settings.contact_email} className="contact-info__contact-list__link"> {this.state.settings.contact_email}</Link>
                                            
                                        </div> 
                                    </div> 
                                    <div className="contact-info__contact-list__item">
                                        <span className="contact-info__contact-list__icon icon-maps-and-flags"></span> 
                                        <div className="contact-info__contact-list__content">
                                            {this.state.settings.contact_address1} 
                                        </div> 
                                    </div>
                                    <div className="contact-info__contact-list__item">
                                        <div className='contact-qr'>
                                            <img src={this.state.settings.contact_khalidiya_map_qr} width="100" alt="Khalidiya Map" />
                                            <p>Scan to View Map</p>
                                        </div>
                                        <div className='contact-qr'>
                                             <img src={this.state.settings.contact_khalidiya_menu_qr} width="100" alt="Khalidiya Menu" />
                                             <p>Scan to View Menu</p>
                                        </div>
                                    </div> 
                                </div> 
                            </div> 
                        </div> 
                    </div> 
                    <div className="col-lg-6 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                        <div className="contact-info__business-hours">
                            <div className="contact-info__business-hours__bg" style={{backgroundImage: `url(assets/images/shapes/contact-bg-1-2.png)`}}></div> 
                            <div className="contact-info__business-hours__content">
                                
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3631.3459658344623!2d54.3520046!3d24.4734669!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e660b586b0a81%3A0xa400a7fb432f250c!2z2YXZhNit2YXYqSDYp9mE2YfYr9mJINin2YTYrtin2YTYr9mK2KkgQUxIVURBIEJVVENIRVJZIEtIQUxJRElZQQ!5e0!3m2!1sen!2sin!4v1733426507800!5m2!1sen!2sin" width="500" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></div> 
                        </div> 
                    </div> 
                </div> 
                <div className="row gutter-y-30  contact-row">
                    <div className="col-lg-6 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="00ms">
                        <div className="contact-info__contact">
                            <div className="contact-info__contact__bg" style={{backgroundImage: `url(assets/images/shapes/contact-bg-1-1.png)`}}></div> 
                            <div className="contact-info__contact__content">
                                <div className="contact-info__top">
                                    <h4 className="contact-info__title">ALHUDA BUTCHERY (KHALIFA)</h4> 
                                    
                                </div> 
                                <div className="contact-info__contact-list">
                                    <div className="contact-info__contact-list__item">
                                        <span className="contact-info__contact-list__icon icon-phone-call"></span> 
                                        <div className="contact-info__contact-list__content">
                                            <span className="contact-info__contact-list__title">call now</span> 
                                            <Link href={"tel: "+this.state.settings.contact_land_number2} className="contact-info__contact-list__link"> {this.state.settings.contact_land_number2}</Link>,
                                            <Link href={"tel: "+this.state.settings.contact_mobile_number2} className="contact-info__contact-list__link"> {this.state.settings.contact_mobile_number2}</Link>
                                             
                                        </div> 
                                    </div> 
                                    <div className="contact-info__contact-list__item">
                                        <span className="contact-info__contact-list__icon icon-paper-plane"></span> 
                                        <div className="contact-info__contact-list__content">
                                            <span className="contact-info__contact-list__title">email</span> 
                                            <Link href={"mailto: "+this.state.settings.contact_email} className="contact-info__contact-list__link"> {this.state.settings.contact_email}</Link>
                                            
                                        </div> 
                                    </div> 
                                    <div className="contact-info__contact-list__item">
                                        <span className="contact-info__contact-list__icon icon-maps-and-flags"></span> 
                                        <div className="contact-info__contact-list__content"> 
                                            {this.state.settings.contact_address2} 
                                        </div> 
                                    </div>
                                    <div className="contact-info__contact-list__item">
                                        <div className='contact-qr'>
                                            <img src={this.state.settings.contact_khalifa_map_qr} width="100" alt="Khalifa Map" />
                                            <p>Scan to View Map</p>
                                        </div> 
                                        <div className='contact-qr'>
                                             <img src={this.state.settings.contact_khalidiya_menu_qr} width="100" alt="Khalidiya Menu" />
                                             <p>Scan to View Menu</p>
                                        </div>
                                    </div> 
                                    
                                </div> 
                            </div> 
                        </div> 
                    </div> 
                    <div className="col-lg-6 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                        <div className="contact-info__business-hours">
                            <div className="contact-info__business-hours__bg" style={{backgroundImage: `url(assets/images/shapes/contact-bg-1-2.png)`}}></div> 
                            <div className="contact-info__business-hours__content">
                                
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3632.208306342325!2d54.61280209999999!3d24.443557199999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e4e3fad4c137b%3A0x37af771ca89457aa!2z2YXZhNit2YXYqSDYp9mE2YfYr9mJINiu2YTZitmB2Kkg2KMg2KfYqNix2KfYrCDYp9mE2KfYqtit2KfYryDYqNmE2KfYstinIEFMSFVEQSBCVVRDSEVSWSBLSEFMSUZBIENJVFk!5e0!3m2!1sen!2sin!4v1733426549086!5m2!1sen!2sin" width="500" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>                            </div> 
                        </div> 
                    </div> 
                </div> 
            </div>
        </section> 
 

           </div>
            
    );
}

}

export default ContactContent;