import { createContext, useState } from 'react';

const SharedContext = createContext();

const SharedProvider = ({ children }) => {
  const [sharedState, setSharedState] = useState(0);

  return (
    <SharedContext.Provider value={{ sharedState, setSharedState }}>
      {children}
    </SharedContext.Provider>
  );
};

export { SharedContext, SharedProvider };