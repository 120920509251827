import React, {Component} from 'react';

 
import axios from 'axios'; 
import { BrowserRouter, Routes, Link, Route } from 'react-router-dom'; 
import {api_base_url, base_url} from '../common/Constants'; 
import { SharedContext } from '../pages/createContext';

class Header extends Component {
    static contextType = SharedContext;
    constructor(){
        super();
        
        this.state = {
            posts : [],
            name:'Login',
            cart : [],
            loading:1,
            cartcount:0,
            settings:{}

        }
    }

    componentDidMount()
    {  
        
        axios.get(api_base_url+"settings").then(response=>{
            this.setState({settings:response.data}) ;
        });
        const user_id =  localStorage.getItem('user_id');
        axios.get(api_base_url+"categories").then(response=>{
            this.setState({posts:response.data})
            localStorage.setItem('categories',response.data) ;

                if(user_id !== "" && user_id !== null  &&  user_id !== undefined)
                { 
               
                   var data = { user_id : user_id}
                    axios.post(api_base_url+"getuser", data).then(response=>{
                        this.setState({name:response.data.name}); 
 
                       

                    });
                 }
                 const order_session_id =  localStorage.getItem('order_session_id');
                 var data = { order_session_id : order_session_id}
                 axios.post(api_base_url+"cart", data).then(response=>{
                     this.setState({cart:response.data});   
                     this.setState({loading:0});  
                     this.setState({cartload:0});  
                     this.setState({cartcount:response.data.cart.length});  
                 });
        });
        
       
    }

    render()
    {
        

  return (
    
    <header className="main-header main-header--one sticky-header sticky-header--normal">
        
        <div className="main-header__inner">
                    <div className="main-header__logo">
                        
                        <Link to="/">
                            <img src={base_url+"assets/images/logo-dark.png"} alt={this.state.settings.site_title} width="150" />
                        </Link>
                    </div>
                    <div className="main-header__right">
                        <div className="topbar">
                            <div className="container-fluid">
                                <div className="topbar__inner">
                                    <ul className="list-unstyled topbar__info">
                                        
                                        <li>
                                            <i className="icon-maps-and-flags"></i>
                                            <Link to="https://maps.app.goo.gl/WK5WbfScfDV9PvfQ8">{this.state.settings.contact_address1}</Link>
                                        </li>
										<li>
                                            <i className="icon-maps-and-flags"></i>
                                            <Link to="https://maps.app.goo.gl/NsD1rwg8kPoZaV8t6">{this.state.settings.contact_address2}</Link>
                                        </li>
                                    </ul> 
                                    <div className="topbar__right">
                                        
                                        <div className="topbar__social">
                                            <Link to={this.state.settings.site_facebook_url}>
                                                <i className="fab fa-facebook-f" aria-hidden="true"></i>
                                                <span className="sr-only">Facebook</span>
                                            </Link>
                                            
                                            
                                            <Link to={this.state.settings.site_instagram_url}>
                                                <i className="fab fa-instagram" aria-hidden="true"></i>
                                                <span className="sr-only">Instagram</span>
                                            </Link>
											<Link to={this.state.settings.site_youtube_url}>
                    						<i className="fab fa-youtube" aria-hidden="true"></i>
                    						<span className="sr-only">Youtube</span>
              								  </Link>
                                        </div> 
                                    </div> 
                                </div> 
                            </div> 
                        </div> 
                        <div className="main-header__right__inner">
                            <div className="main-header__right__left">
                                <nav className="main-header__nav main-menu">
                                    <ul className="main-menu__list">

                                        <li>
                                            <Link to="/">Home</Link>
                                            
                                        </li>


                                        
                                        
                                        <li className="dropdown">
                                            <Link to="#">Our Products</Link>
                                            <ul className="sub-menu">
                                                <li><Link to={base_url+"shop"}>All Products</Link></li>
                                                {this.state.posts.map((item,key)=>{
                                                    return <li><Link  to={base_url+`category/${item.slug}`} >{item.name}</Link></li>
                                                })}
                                                 
                                            </ul>
                                        </li>
										
										<li>
                                            <Link to={base_url+"gallery"}>Gallery</Link>
                                        </li>
                                        <li>
                                            <Link to={base_url+"blog"}>Blog</Link>
                                            
                                        </li>
                                        <li>
                                            <Link to={base_url+"about-us"}>About Us</Link>
                                        </li>
                                        
										<li>
                                            <Link to={base_url+"our-companies"}>Our Companies</Link>
                                            
                                        </li>
                                        <li>
                                            <Link to={base_url+"careers"}>Careers</Link>
                                        </li>
                                        
                                        <li>
                                            <Link to={base_url+"contact-us"}>Contact Us</Link>
                                        </li>
                                    </ul>
                                </nav> 
                            </div> 
                            <div className="main-header__right__right">
                                <div className="mobile-nav__btn mobile-nav__toggler">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div> 
                                 
                                <Link to="#" className="search-toggler main-header__search">
                                    <i className="icon-search" aria-hidden="true"></i>
                                    <span className="sr-only">Search</span>
                                </Link> 
                                <Link to="/cart" className="main-header__cart">
                                    <i className="icon-cart" aria-hidden="true"></i>
                                    <span className="sr-only">Cart</span>
                                    <span className={this.props.cartpage  ? 'cart-count dblock' : 'cart-count dnone' }  >{this.context.sharedState}</span>
                                    <span className={!this.props.cartpage ? 'cart-count dblock' : 'cart-count dnone' }  >{this.state.cartcount}</span>
                                    </Link> 

                                <Link  to={base_url+"user/login"} className="boskery-btn main-header__btn "  id={this.state.name == "Login" ? "dblock" : "dnone"} >
                                    <span className="boskery-btn__hover"></span>
                                    <span className="boskery-btn__hover"></span>
                                    <span className="boskery-btn__hover"></span>
                                    <span className="boskery-btn__hover"></span>
                                    <span className="boskery-btn__hover"></span>
                                    <span className="boskery-btn__hover"></span>
                                    <span className="boskery-btn__text">Login</span>
                                    <i className="icon-meat-3"></i>
                                </Link>
                                
                                <Link  to={base_url+"user/profile"} className="boskery-btn main-header__btn "  id={this.state.name == "Login" ? "dnone" : "dblock"} >
                                    <span className="boskery-btn__hover"></span>
                                    <span className="boskery-btn__hover"></span>
                                    <span className="boskery-btn__hover"></span>
                                    <span className="boskery-btn__hover"></span>
                                    <span className="boskery-btn__hover"></span>
                                    <span className="boskery-btn__hover"></span>
                                    <span className="boskery-btn__text">{this.state.name}</span>
                                    <i className="icon-meat-3"></i>
                                </Link> 
                            </div> 
                        </div> 
                    </div> 
        </div>
    </header>
    
  );
}
};

export default Header;