import React, { Component } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const defaultLocation = { lat: 37.7749, lng: -122.4194 };

class LocationPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: defaultLocation,
    };
  }

  handleMarkerDragEnd = (e) => {
    this.setState({
      location: { lat: e.latLng.lat(), lng: e.latLng.lng() },
    });
  };

  render() {
    return (
      <form>
        <LoadScript googleMapsApiKey="YOUR_API_KEY">
          <GoogleMap
            mapContainerStyle={{ height: '400px', width: '800px' }}
            center={this.state.location}
            zoom={13}
          >
            <Marker
              position={this.state.location}
              onDragEnd={this.handleMarkerDragEnd}
              draggable={true}
            />
          </GoogleMap>
        </LoadScript>
        <input
          type="text"
          value={this.state.location.lat}
          onChange={(e) => this.setState({ location: { ...this.state.location, lat: e.target.value } })}
          placeholder="Latitude"
        />
        <input
          type="text"
          value={this.state.location.lng}
          onChange={(e) => this.setState({ location: { ...this.state.location, lng: e.target.value } })}
          placeholder="Longitude"
        />
      </form>
    );
  }
}

export default LocationPicker;