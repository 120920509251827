import React, {Component} from 'react';
import { BrowserRouter, Switch, Link, Route } from 'react-router-dom'; 
 
import axios from 'axios';
import {api_base_url, base_url} from '../common/Constants';
 


 
class PopularProducts extends React.Component{

    constructor(props){
        super(props); 
    }
 
    render()
    { 
      
    return (
        <section className="page-header">
        <div className="page-header__bg" style={{backgroundImage:`url(`+base_url+`assets/images/backgrounds/page-header-bg.jpg)`}}></div>
       
        <div className="container">
            <h2 className="page-header__title">{this.props.title} : {this.props.slug}</h2>
            <ul className="boskery-breadcrumb list-unstyled">
                <li><Link to="/">Home</Link></li>
                <li><span>{this.props.title}</span></li>
            </ul> 
        </div> 
    </section> 

    );
}

}

export default PopularProducts;