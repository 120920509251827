import React, {Component} from 'react';
import { BrowserRouter, Switch, Link, Route } from 'react-router-dom';
import SuccessContent from '../components/SuccessContent'; 
import axios from 'axios'; 

import Header from '../common/Header'; 
import Footer from '../common/Footer';
import PageHeader from '../components/PageHeader'; 
 
class Success extends React.Component{

    constructor(){
        super(); 
    }
 
    render()
    {
       
        return (
            <div className="page-wrapper">
                <Header/> 
                <PageHeader title="Success"/>
                <SuccessContent/>  
                <Footer/>
            </div>
       
        )
    }

}
export default Success;