 
import React, {Component} from 'react';
import { BrowserRouter, Switch, Link, Route , useNavigate} from 'react-router-dom'; 
 
import axios from 'axios';   
import {api_base_url, base_url} from '../../common/Constants';
import LeftMenu,{AppWithRouter} from './LeftMenu';
 
class ChangePassword extends React.Component{

    constructor(props){
        super(props);

        
        this.state = { 
            user:{},
            show:false,
            curpassword:'',
            password:'',
            cpassword:'', 
            errors:{},
            fopen:false,
            message:'',
            reg_error:false,
            err_message:""
        }
        

    }

    componentDidMount()
    {
        const user_id =  localStorage.getItem('user_id');
        
        if(user_id !== "" && user_id !== null  &&  user_id !== undefined)
        { 
            var data = { user_id : user_id}
            axios.post(api_base_url+"getuser", data).then(response=>{
                this.setState({user:response.data});  
                this.setState({show:true});   
            });
        }
        else
        {
            this.props.navigate('user/login');
        }

    }

    handleSubmit = (event) => {
        event.preventDefault();
        const errors = {};
        if (!this.state.curpassword ) {
            errors.curpassword = 'Current password is required';
        }
        else if (this.state.curpassword.length < 6) {
            errors.curpassword = 'Current password should have atleast 6 letters';
        }
        if (!this.state.password ) {
            errors.password = 'Password is required';
        }
        else if (this.state.password.length < 6) {
            errors.password = 'Password should have atleast 6 letters';
        }
        if (!this.state.cpassword ) {
            errors.cpassword = 'Confirm password is required';
        }
        else if (this.state.cpassword.length < 6) {
            errors.cpassword = 'Confirm password should have atleast 6 letters';
        } 
        else if (this.state.password != this.state.cpassword ) {
            errors.cpassword = 'Password & Confirm password should be same';
        }
         
        if (Object.keys(errors).length > 0) {
          this.setState({ errors });
        } else {
          
            this.setState({ errors });
            var data = {  data:this.state, 'user_id' :this.state.user.id  }
            axios.post(api_base_url+"user/change_password", data).then(response=>{

                if(response.data.error)
                {
                    this.setState({reg_error:true});
                    this.setState({fopen:false});
                    this.setState({err_message: response.data.message});  
                }
                else{
                    this.setState({curpassword:''});
                    this.setState({password:''});
                    this.setState({cpassword:''});
                    this.setState({fopen:true});
                    this.setState({reg_error:false});
                    this.setState({message:" Successfully updated."});
                    setTimeout(function(){
                        //window.location.href = '/user/profile';
                    },3000);
                }
               
                //this.setState({message:" Thank you for registering with us. A mail has been sent to your email address. Please follow the instructions in the mail to verify your"});
                //localStorage.setItem('user_id',response.data.id) ;
                //window.location.href = '/confirm-order';

            }).catch((error) => { 
                  console.log('Conflict error:', error.response);
               
            });

        }
      };
    
    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
      };
  

    render()
    { 
        if (this.state.loading == 1) {
            return <div>Loading...</div>;
          }
    return (
        <section className="profile-page">
            <div className="container">
                <div className="row">
                    <div className="col-md-3 col-12">
                        <AppWithRouter selected="change-password" />
                    </div>
                    <div className="col-md-9 col-12">
                        <div className='user-data' id={!this.state.show ? "dnone" : "dblock"}>
                        <form className="login-page__form">
                            <div className='row'>
                            <div className="col-md-12 col-12 login-page__form__input-box  ">
                                    <input type="password" placeholder="Current Password" name="curpassword" id="curpassword"  
                                        required="" onChange={this.handleChange} value={this.state.curpassword} />
                                    {this.state.errors.curpassword && <label className="error">{this.state.errors.curpassword}</label>}
                                </div> 
                                <div className="col-md-12 col-12 login-page__form__input-box  ">
                                    <input type="password" placeholder="New Password" name="password" id="password"  
                                        required="" onChange={this.handleChange} value={this.state.password} />
                                    {this.state.errors.password && <label className="error">{this.state.errors.password}</label>}
                                </div> 
                                <div className="col-md-12 col-12 login-page__form__input-box  ">
                                    <input type="password" placeholder="Confirm Password" name="cpassword" id="cpassword"  
                                        required="" onChange={this.handleChange} value={this.state.cpassword} />
                                    {this.state.errors.cpassword && <label className="error">{this.state.errors.cpassword}</label>}
                                </div> 
                                  
                            </div>
                                          
                                            <div className='row'>              
                                                    <div className="col-md-6 col-12  login-page__form__input-box">
                                                        
                                                    </div>  
                                                    <div className=" col-md-6 col-12 login-page__form__input-box login-page__form__input-box--button"><br></br>
                                                    <button type="button" onClick={this.handleSubmit} className="boskery-btn login-page__form__btn">Update</button>
                                                </div> 
                                            </div>
                                            

                                            <div className='alert alert-success '  id={this.state.fopen ? "dblock" : "dnone"}>{this.state.message}</div>
                        
                                            <div className='alert alert-warning '  id={this.state.reg_error ? "dblock" : "dnone"}>{this.state.err_message}</div>
                        
 
                                        </form>
                                        <br/><br/>
                        </div>

                        <div className='loading '  id={this.state.show ? "dnone" : "dblock"}> <img src={base_url+"assets/images/loader.gif"} alt="Alhuda" width="150" /></div>
                    
                    </div>
                </div>
            </div>
        </section>
    )
    }

}

export function AppWithNavigate(props){
    const navigate = useNavigate();
    return  (<ChangePassword navigate={navigate} ></ChangePassword>)
}

export default ChangePassword;