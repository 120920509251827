import React, {Component} from 'react';
import { BrowserRouter, Switch, Link, Route } from 'react-router-dom';
import AboutHome from '../components/AboutHome';
import PopularProducts from '../components/PopularProducts';
import SpecialOfferProducts from '../components/SpecialOfferProducts';
import BlogsHome from '../components/BlogsHome';

import axios from 'axios'; 
import Header from '../common/Header'; 
import Footer from '../common/Footer';
import Slider from '../common/Slider';
import {api_base_url, base_url} from '../common/Constants';
 
class Home extends React.Component{

    constructor(){
        super();
        this.state = {
            categories : [],
        }
    }

    componentDidMount()
    {
        axios.get(api_base_url+"categories").then(response=>{
            this.setState({categories:response.data});  
           
        }); 
       
    }


    render()
    {
       
        return (

            <div className="page-wrapper">
      <Header/> 

        <Slider/>
            <section className="gallery-page section-space">
            <div className="container">
                <div className="row gutter-y-20">
                 
                {this.state.categories.map((cat,key)=>{
                    return <div className="col-md-6 col-lg-2">
                      
                                                    <Link to={`/category/${cat.slug}`} className='cat-icon' style={{backgroundImage: `url(${cat.icon})`}}>  </Link>
                                                     
                                                
                                             </div> 
                 })}
  
                    </div>  
                </div> 
        </section>


        <AboutHome/>
        <SpecialOfferProducts/>
       <PopularProducts/>
       
        


        <section className="why-choose-one section-space-two" id="why-choose">
            <div className="container">
                <div className="sec-title sec-title--center">

                    <img src="assets/images/shapes/sec-title-s-1.png" alt="why choose us" className="sec-title__img" />


                    <h6 className="sec-title__tagline">why choose us</h6> 
                    <h2 className="sec-title__title">We Provide Best Meat <br/> From Our Shop</h2> 
                </div> 
                <div className="row gutter-y-30 align-items-center">
                    <div className="col-lg-4 wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="00ms">
                        <div className="why-choose-one__content">
                            <div className="why-choose-one__item">
                                <div className="why-choose-one__item__icon">
                                    <span className="icon-grass"></span>
                                </div> 
                                <div className="why-choose-one__item__content">
                                    <h4 className="why-choose-one__item__title">100% Grass-Fed</h4> 
                                    <p className="why-choose-one__item__text">A meat shop is a retail establhment that specializes in</p> 
                                </div> 
                            </div> 
                            <div className="why-choose-one__item">
                                <div className="why-choose-one__item__icon">
                                    <span className="icon-fast-delivery"></span>
                                </div> 
                                <div className="why-choose-one__item__content">
                                    <h4 className="why-choose-one__item__title">free home delivery</h4> 
                                    <p className="why-choose-one__item__text">A meat shop is a retail establhment that specializes in</p> 
                                </div> 
                            </div> 
                            <div className="why-choose-one__item">
                                <div className="why-choose-one__item__icon">
                                    <span className="icon-achievement"></span>
                                </div> 
                                <div className="why-choose-one__item__content">
                                    <h4 className="why-choose-one__item__title">Quality Control</h4> 
                                    <p className="why-choose-one__item__text">A meat shop is a retail establhment that specializes in</p> 
                                </div> 
                            </div> 
                        </div> 
                    </div> 
                    <div className="col-lg-4 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="400ms">
                        <div className="why-choose-one__image">
                            <img src="assets/images/shapes/why-choose-shape-1-1.png" alt="why-choose-shape" />
                        </div> 
                    </div>
                    <div className="col-lg-4 wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="00ms">
                        <div className="why-choose-one__content">
                            <div className="why-choose-one__item">
                                <div className="why-choose-one__item__icon">
                                    <span className="icon-no-preservatives"></span>
                                </div> 
                                <div className="why-choose-one__item__content">
                                    <h4 className="why-choose-one__item__title">no chemical use</h4> 
                                    <p className="why-choose-one__item__text">A meat shop is a retail establhment that specializes in</p> 
                                </div> 
                            </div> 
                            <div className="why-choose-one__item">
                                <div className="why-choose-one__item__icon">
                                    <span className="icon-healthcare-1"></span>
                                </div> 
                                <div className="why-choose-one__item__content">
                                    <h4 className="why-choose-one__item__title">Health and Safety</h4> 
                                    <p className="why-choose-one__item__text">A meat shop is a retail establhment that specializes in</p> 
                                </div> 
                            </div> 
                            <div className="why-choose-one__item">
                                <div className="why-choose-one__item__icon">
                                    <span className="icon-meat"></span>
                                </div> 
                                <div className="why-choose-one__item__content">
                                    <h4 className="why-choose-one__item__title">100% Fresh Produce</h4> 
                                    <p className="why-choose-one__item__text">A meat shop is a retail establhment that specializes in</p> 
                                </div> 
                            </div> 
                        </div> 
                    </div>
                </div>
            </div>
        </section>
 
        <BlogsHome/>
        <Footer/>
       

      <div className="custom-cursor__cursor"></div>
      <div className="custom-cursor__cursor-two"></div>
      </div>
       
        )
    }

}
export default Home;