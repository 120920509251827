import React, {Component} from 'react';
import { BrowserRouter, Switch, Link, Route } from 'react-router-dom'; 
 
import axios from 'axios'; 
import {api_base_url, base_url} from '../common/Constants';

 
class AboutContent extends React.Component{

    constructor(){
        super();

        
        this.state = {
            content : [],
            loading : 1,
            settings:{}
        }
        

    }

    componentDidMount()
    {
        axios.get(api_base_url+"settings").then(response=>{
            this.setState({settings:response.data}) ;
        });
        axios.get(api_base_url+"content").then(response=>{
            this.setState({content:response.data[0]}); 
            this.setState({loading:0})
        });
    }


    render()
    { 
        if (this.state.loading == 1) {
            return <div>Loading...</div>;
          }
    return (
        <div>
  <section className="about-three section-space" id="about">
            <div className="container">
            <div className="row gutter-y-60">
            <div className='col-lg-5'></div>
            <div className='col-lg-2'><img src={this.state.content.image}  alt={this.state.settings.site_title}  className="sec-title__img" width="200" />
            </div>
            <div className='col-lg-5'></div>
            </div>
                <div className="row gutter-y-60">
                
                    <div className="col-lg-12 wow fadeInRight" data-wow-duration="1500ms">
                        
                        <div className="about-three__content">
                              
                        <div dangerouslySetInnerHTML={{ __html: this.state.content.body }} />  
                            
                        </div> 
                    </div> 
                </div> 
            </div> 
            
        </section> 

        <section className="about-three section-space" id="about">
            <div className="container">
                <div className="row gutter-y-60">
                    <div className="col-lg-6 wow fadeInLeft" data-wow-duration="1500ms">
					
                        <div className="about-three__image">
                            <img src="assets/images/founder.jpg" alt="about image" className="about-three__image__one" />
                            
                            
                        </div> 
                    </div> 
                    <div className="col-lg-6 wow fadeInRight" data-wow-duration="1500ms">
                        <div className="about-three__content">
                            <div className="sec-title @@extraClassName">
                            <img src="assets/images/shapes/sec-title-s-1.png" alt="DELIVERY SERVICE" className="sec-title__img" />

                                <h4 className="sec-title__title">The Founder</h4> 
                            </div> 
                            <p className="about-three__text">Alhuda Gulf Foodstuff Co, Ltd has been an anchor of the international meat & pastries industry for 25 years.
                                 Situated on United Arab Emirates Abu Dhabi, it has started with a small butchery shop and rapidly grown from the meat product to a
                                  trading company with the capacity of producing pastries using the fresh meat and vegetables using our gained experience and 
                                  special Arabic recipe. Alhuda Gulf Foodstuff Co, Ltd. is renowned for its high quality foodstuff & pastries products. 
                                  It’s a pride to be a major foodstuff & pastries to 4 / 5 star hotels, caterers, Airlines which ultimately reflects 
                                  their willingness and trust in our product and service. <br /><br />
							 </p> 
                            
                            
                            
                        </div> 
                    </div> 
                </div> 
            </div> 
            
        </section> 


        <section className="delivery-one section-space" id="delivery">
            <div className="container">
                <div className="row gutter-y-60">
                    <div className="col-lg-6 wow fadeInLeft" data-wow-duration="1500ms">
                        <div className="delivery-one__content">
                            <div className="sec-title @@extraClassName">

                                <img src="assets/images/shapes/sec-title-s-1.png" alt="DELIVERY SERVICE" className="sec-title__img" />
 
                                <h2 className="sec-title__title">The OWNER</h2> 
                            </div> 
                            <p className="delivery-one__text">Our access to quality material, experience of products processing, utilizing of rigid quality control standards can guarantee superior quality of the products. Our skilled workers and quality control staffs can meet the most sophisticated specifications for all types of processing requirements, at the same time, our strength in sourcing ensures the steady supply of quality meat & pastries products. We are trying utmost with heart and soul to supply quality products with competitive prices; timely shipments; excellent service; as our mission statement indicates that: “The best deserves the best.” </p> 
                            
                        </div> 
                    </div> 
                    <div className="col-lg-6 wow fadeInRight" data-wow-duration="1500ms">
                        <div className="delivery-one__image">
                            <div className="about-three__image">
                                <img src="assets/images/founder.jpg" alt="delivery man" className="delivery-one__image__one" />
                                
                                
                            </div> 
                            
                        </div> 
                    </div> 
                </div> 
            </div> 
            
        </section> 
        <br />
        <br />
        </div>
    );
}

}

export default AboutContent;