import React, {Component, useState, useEffect} from 'react';
import { BrowserRouter, Switch, Link, Route } from 'react-router-dom';
import CartContent from '../components/CartContent'; 
import axios from 'axios'; 
import Header from '../common/Header'; 
import Footer from '../common/Footer';
import PageHeader from '../components/PageHeader'; 
import withRouter from '../components/withRouter';
import {api_base_url, base_url} from '../common/Constants'; 
import { SharedContext } from '../pages/createContext';

class Cart extends React.Component{

    static contextType = SharedContext;
    constructor(props){
        super(props);
       
        this.state = {
            cartcount : 0
        }

    }

    componentDidMount()
    {  
       
    }
 
 
    render()
    { 
        return (
            <div>
                <Header cartpage={true} cartcount={this.state.cartcount} updateSharedState={this.updateSharedState} /> 
                <PageHeader title="Shopping Cart"  />
                <CartContent />  
                <Footer/>
      
        
        </div>
       
        )
    }

}
export default withRouter(Cart);;