import React, {Component, useState, useEffect} from 'react';
import { BrowserRouter, Switch, Link, Route } from 'react-router-dom';
import ContactContent from '../components/ContactContent'; 
import axios from 'axios'; 
import Header from '../common/Header'; 
import Footer from '../common/Footer';
import PageHeader from '../components/PageHeader'; 
import withRouter from '../components/withRouter';

class Contact extends React.Component{

    constructor(){
        super();  
    }
 
    render()
    { 
        return (
            <div>
                <Header/> 
                <PageHeader title="Contact Us"  />
                <ContactContent   />  
                <Footer/>
      
        
        </div>
       
        )
    }

}
export default withRouter(Contact);;