import React, {Component} from 'react';
import { BrowserRouter, Switch, Link, Route } from 'react-router-dom'; 
 
import axios from 'axios'; 

 
class AboutHome extends React.Component{

    constructor(){
        super();

        
        this.state = {
            banners : [],
            loading : 1
        }
        

    }

    componentDidMount()
    {
        axios.get("https://alhuda.integratesolu.com/public/api/content").then(response=>{
            this.setState({content:response.data}); 
            this.setState({loading:0})
        });
    }


    render()
    { 
        if (this.state.loading == 1) {
            return <div>Loading...</div>;
          }
    return (
        <section className="about-one section-space-top" id="about" style={{backgroundImage: `url(assets/images/shapes/why-choose-bg-1-1.png)`}}>
        <div className="container">
            <div className="row gutter-y-60">
                <div className="col-lg-6 wow fadeInLeft" data-wow-duration="1500ms">
                    <div className="about-one__image">
                        <div className="about-one__image__inner">
                            <h3 className="about-one__image__text">since 1979</h3> 

                            <img src={this.state.content[1].image} alt="about image" />
                           
                                <span className="icon-play"></span>
                                <i className="video-button__ripple"></i>
                            
                            <div className="about-one__image__border"></div>
                        </div> 
                        <img src="assets/images/shapes/about-shape-1-1.png" alt="about shape" className="about-one__image__shape" />
                    </div> 
                </div>
                <div className="col-lg-6 wow fadeInRight" data-wow-duration="1500ms">

                    <div className="about-one__content">
                        <div className="sec-title @@extraClassName">

                            <img src="assets/images/shapes/sec-title-s-1.png" alt="about Al Huda meat shop" className="sec-title__img" />

                            
                            <h6 className="sec-title__tagline">{this.state.content[1].title}</h6> 

                            <h2 className="sec-title__title">{this.state.content[1].subtitle}</h2> 
                        </div> 
                        <p className="about-one__text"><div dangerouslySetInnerHTML={{ __html: this.state.content[1].body }} /> </p> 
                        <div className="about-one__inner"> 
                            <ul className="about-one__info">
                                <li>
                                    <span className="icon-check-mark"></span>
                                    Premium Cuts
                                </li>
                                <li>
                                    <span className="icon-check-mark"></span>
                                    Quality Sourcing
                                </li>
                                <li>
                                    <span className="icon-check-mark"></span>
                                    Custom Butchering
                                </li>
                                <li>
                                    <span className="icon-check-mark"></span>
                                    Freshness
                                </li>
                                <li>
                                    <span className="icon-check-mark"></span>
                                    best Reviews
                                </li>
                                <li>
                                    <span className="icon-check-mark"></span>
                                    100% organic
                                </li>
                            </ul> 
                        </div> 
                        <div className="about-one__bottom">
                            <Link to="/about-us" className="boskery-btn">
                                <span className="boskery-btn__hover"></span>
                                <span className="boskery-btn__hover"></span>
                                <span className="boskery-btn__hover"></span>
                                <span className="boskery-btn__hover"></span>
                                <span className="boskery-btn__hover"></span>
                                <span className="boskery-btn__hover"></span>
                                <span className="boskery-btn__text">know about us</span>
                                <i className="icon-meat-3"></i>
                            </Link> 
                            <div className="contact__info">
                                <div className="contact__info__inner">
                                    <div className="contact__info__icon">
                                        <span className="icon-telephone"></span>
                                    </div>
                                    <div className="contact__info__right">
                                        <h4 className="contact__info__title">Call for Delivery</h4> 
                                        <a href="tel:+971 2 6679939" className="contact__info__number">+971 2 6679939</a> 
                                    </div> 
                                </div> 
                            </div> 
                        </div>
                
                    </div>
                  
                </div> 
         </div>
        <div className="about-one__shape">
            <img src="assets/images/shapes/about-shape-1-2.png" alt="about shape" className="about-one__shape__one" />
            <img src="assets/images/shapes/about-shape-1-3.png" alt="about shape" className="about-one__shape__two" />
        </div> 
        
        </div>
    </section> 
    );
}

}

export default AboutHome;