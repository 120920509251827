 
import React, {Component} from 'react';
import { BrowserRouter, Switch, Link, Route } from 'react-router-dom'; 
 
import axios from 'axios';   
import {api_base_url, base_url} from '../../common/Constants';
import LeftMenu,{AppWithRouter} from './LeftMenu';
 
 
class ViewOrderContent extends React.Component{

    constructor(props){
        super(props);
        this.state = { 
            order:{},
            items:[],
            show:false
        }
    }

    componentDidMount()
    {
        const order_id =  this.props.id - 1000;
 
        if(order_id  > 0)
        { 
            var data = { order_id : order_id}
            axios.post(api_base_url+"get_order_info", data).then(response=>{
                this.setState({order:response.data});  
                this.setState({items:response.data.items});  
                this.setState({show:true});  
            });
        }
        else
        {
            //window.location.href = base_url+'user/login';
        }
    }

    render()
    { 
        if (this.state.loading == 1) {
            return <div>Loading...</div>;
          }
    return (
        <section className="profile-page">
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <AppWithRouter selected="orders" />
                    </div>
                    <div className="col-md-9">
                        <div className='order-data user-data'  id={this.state.show ? "dblock" : "dnone"}  >
                        <h3>Order Details</h3>
                            
                             <div className='order-info user-info'>
                            <p><b>Order No: </b>{this.state.order.order_no}</p>
                            <p><b>Order date: </b>{this.state.order.order_date}</p>
                            <p><b>Total: </b>{this.state.order.order_total}</p>
                            <p><b>Status: </b>{this.state.order.order_status}</p>
                            <p><b>Payment Status: </b>{this.state.order.payment_status}</p>
                            <p><b>Payment Method: </b>{this.state.order.payment_method}</p>
                            <p><b>Comments: </b>{this.state.order.user_comments}</p>
                             
                            </div>
                            <br/> 
                            <table>
                                <tr>
                                    <td>
                                        <h3>Billing Details</h3>
                                        
                                        <div className='order-info user-info'>
                                            <p>{this.state.order.billing_firstname} {this.state.order.billing_lastname},</p> 
                                            <p>{this.state.order.billing_address1}, {this.state.order.billing_address2},</p> 
                                            <p>{this.state.order.billing_city}, {this.state.order.billing_state}, {this.state.order.billing_country}</p>  
                                            <p><b>Email Address: </b>{this.state.order.billing_email}</p> 
                                            <p><b>Phone Number: </b>{this.state.order.billing_phone}</p> 
                                            
                                        </div>
                                    
                                    </td>
                                    <td width="30"></td>
                                    <td>
                                         <h3>Shipping Details</h3>
                            
                                            <div className='order-info user-info'>
                                                <p>{this.state.order.shipping_firstname} {this.state.order.shipping_lastname},</p> 
                                                <p>{this.state.order.shipping_address1}, {this.state.order.shipping_address2},</p> 
                                                <p>{this.state.order.shipping_city}, {this.state.order.shipping_state}, {this.state.order.shipping_country}</p>  
                                                <p><b>Email Address: </b>{this.state.order.shipping_email}</p> 
                                                <p><b>Phone Number: </b>{this.state.order.shipping_phone}</p> 
                                                
                                            </div>
                                    </td>
                                </tr>    
                            </table> 
                            <br/>

                            <h3>Order Items</h3>
                            
                            <div className='order-info user-info'>
                                 <table className='itemTable'>
                                <tr>
                                    <td>Sl No</td>
                                    <td>Product</td>
                                    <td>Price</td>
                                    <td>Quantity</td>
                                    <td>Total</td>  
                                </tr>

                                {this.state.items.map((item, index) => (
                                    <tr>
                                        <td>{index+1}</td>
                                        <td><Link to={base_url+"products/"+item.slug}>{item.title}</Link></td>
                                        <td>{item.price}</td>
                                        <td>{item.quantity}</td>
                                        <td>{item.total_price}</td>  
                                    </tr>  

                                    ))}

<tr>
                                    <td align="right" colspan="4" className='colSpan4 alignRight'><b>Sub Total</b></td>
                                    <td className='alignRight'><b>{this.state.order.subtotal}</b></td> 
                                </tr>   
                                <tr>
                                    <td align="right" colspan="4" className='colSpan4 alignRight'><b>Shipping Charge</b></td>
                                    <td className='alignRight'><b>{this.state.order.shipping_charge}</b></td> 
                                </tr>   
                            
                                <tr>
                                    <td align="right" colspan="4" className='colSpan4 alignRight'><b>Tax</b></td>
                                    <td className='alignRight'><b>{this.state.order.vat_price}</b></td> 
                                </tr>   
                            
                                <tr>
                                    <td align="right" colspan="4" className='colSpan4 alignRight grand-col'><b>Grand Total</b></td>
                                    <td className='alignRight grand-col'><b>{this.state.order.order_total}</b></td> 
                                </tr>   
                            
                            </table>
                                
                            </div>
                            
                        </div>
                        <div className='loading '  id={this.state.show ? "dnone" : "dblock"}> <img src={base_url+"assets/images/loader.gif"} alt="Alhuda" width="150" /></div>
                    </div>
                </div>
            </div>
        </section>
    )
    }

}

export default ViewOrderContent