import React, {Component} from 'react';
import { BrowserRouter, Switch, Link, Route } from 'react-router-dom'; 
 
import axios from 'axios'; 

 
class SuccessContent extends React.Component{

    constructor(){
        super();

        
        
        this.state = {
            content : [],
            loading : 1,
            order:[]
        }
        

    }

    componentDidMount()
    {
        const order_id =  localStorage.getItem('order_id');
        if(order_id === "" ||order_id === null || order_id === undefined)
            {
                window.location.href = '/';
            }
            else{
        var data = { order_id : order_id}
        axios.post("https://alhuda.integratesolu.com/public/api/get_order", data).then(response=>{
            this.setState({order:response.data}); 
            this.setState({loading:0});  

            localStorage.removeItem('order_session_id');
            localStorage.removeItem('order_id');

        });
    }
    }

     


    render()
    { 
        if (this.state.loading == 1) {
            return <div>Loading...</div>;
          }
    return (
        <div>


        <section className="about-three section-space" id="about">
            <div className="container">
                <div className="row gutter-y-60">
                    <div className="col-lg-12 wow fadeInLeft" data-wow-duration="1500ms">
					
                        <div className="about-three__image">
                           <p>Thank you for your order. One of our executives will contact you soon!</p>

                           <h3>Order Info</h3>

                           <p><b>Order No:</b> {this.state.order.order_no}</p>
                           <p><b>Order Total:</b> {this.state.order.order_total}</p>
                           <p><b>Order Status:</b> {this.state.order.order_status}</p>
                           <p><b>Payment Status:</b> {this.state.order.payment_status}</p>
                            
                        </div> 
                    </div>  
                </div> 
            </div> 
            
        </section> 
 
        <br />
        <br />
        </div>
    );
}

}

export default SuccessContent;