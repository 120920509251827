import React, {Component} from 'react';
import { BrowserRouter, Switch, Link, Route } from 'react-router-dom'; 
 
import axios from 'axios'; 

 
class CareersContent extends React.Component{

    constructor(){
        super();

        
        this.state = {
            careers : [],
            loading : 1
        }
        
    }

    componentDidMount()
    {
        axios.get("https://alhuda.integratesolu.com/public/api/careers").then(response=>{
            this.setState({careers:response.data}); 
            this.setState({loading:0})
        });
    }

    render()
    { 
        if (this.state.loading == 1) {
            return <div>Loading...</div>;
          }
    return (
        <section className="about-three section-space" id="about">
            <div className="container">
            <div className="row gutter-y-30 careers-list">

             
                {this.state.careers.map((career,key)=>{
 
                    return <div className="col-md-6 col-lg-6">
                    <div className="blog-card wow fadeInUp" data-wow-duration='1500ms' data-wow-delay='000ms'>
                        <div className="blog-card__content">
                            <div className="blog-card__top">
                                <div className='career-title'>
                                    <div className="blog-card__date"><span>{career.created_date}</span> <span>{career.created_month}</span></div> 
                                    <h3 className="blog-card__title">{career.title}</h3>
                                </div>
                                <div className='career-info'>
                                    <ul className="list-unstyled blog-card__meta">
                                    <li> <b>Position: </b>{career.position}</li> 
                                    <li> <b>Qualification: </b>{career.qualification}</li> 
                                    </ul> 
                                    <div className="blog-card__description"><p>{career.description}</p></div> 
                                    <div className="blog-card__description"><b>Appy To: </b><p>{career.apply_to}</p></div> 
                                </div>
                                
                            </div> 
                             
                        </div> 
                    </div> 
                </div>  
                 })}
   

                
   </div>
   </div>
         </section>   
    );
}

}

export default CareersContent;