import React, {Component} from 'react';
import { BrowserRouter, Switch, Link, Route } from 'react-router-dom';
import AboutContent from '../components/AboutContent'; 
import axios from 'axios'; 

import Header from '../common/Header'; 
import Footer from '../common/Footer';
import PageHeader from '../components/PageHeader'; 
 
class About extends React.Component{

    constructor(){
        super(); 
    }
 
    render()
    {
       
        return (
            <div className="page-wrapper">
                <Header/> 
                <PageHeader title="About Us"/>
                <AboutContent/>  
                <Footer/>
            </div>
       
        )
    }

}
export default About;