import React, {Component} from 'react';
import { BrowserRouter, Switch, Link, Route } from 'react-router-dom'; 
 
import axios from 'axios';
 
class Slider extends React.Component{

    constructor(){
        super();

        
        this.state = {
            banners : [],
            loading : 1
        }
        

    }

    componentDidMount()
    {
        axios.get("https://alhuda.integratesolu.com/public/api/banners").then(response=>{
            this.setState({banners:response.data});
            this.setState({loading:0});
            
        });
    }


    render()
    { 
    return (
        <section className="hero-slider-one hero-main-slider" id="home">
        <div id="boskery-owl__carousel--with-counter" className="hero-slider-one__carousel boskery-owl__carousel--with-counter owl-carousel" data-owl-options='{
    "loop": true,
    "animateIn": "fadeIn",
    "animateOut": "slideOutDown",
    "items": 1,
    "autoplay": true,
    "autoplayTimeout": 7000,
    "smartSpeed": 1000,
    "nav": false,
    "navText": ["<span className=\"icon-left-arrow\"></span>","<span className=\"icon-right-arrow\"></span>"],
    "dots": true,
    "margin": 0
    }'>
            <div className="item">
                <div className="hero-slider-one__item">
                    <div className="hero-slider-one__bg" style={{backgroundImage: `url("assets/images/backgrounds/slider-1-1.jpg")`}} ></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12 col-xl-10 col-lg-10">
                                <div className="hero-slider-one__content">
                                   <h5 className="hero-slider-one__sub-title">ALHUDA BUTCHERY</h5> 
                                    <h3 className="hero-slider-one__title">
                                        The Finest Meat <br/> Market
                                        <span className="hero-slider-one__title__overlay-group">
                                            
                                        </span>
                                    </h3> 
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            <div className="item">
                <div className="hero-slider-one__item">
                    <div className="hero-slider-one__bg" style={{backgroundImage: `url("assets/images/backgrounds/slider-1-2.jpg")` }} ></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12 col-xl-10 col-lg-10">
                                <div className="hero-slider-one__content">
                                    <h5 className="hero-slider-one__sub-title">ALHUDA BUTCHERY</h5> 
                                    <h2 className="hero-slider-one__title">
                                        Experence <br/> Excellence
                                        <span className="hero-slider-one__title__overlay-group">
                                             
                                            
                                        </span>
                                    </h2> 
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            <div className="item">
                <div className="hero-slider-one__item">
                    <div className="hero-slider-one__bg" style={ {backgroundImage: `url("assets/images/backgrounds/slider-1-3.jpg")` } }></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12 col-xl-10 col-lg-10">
                                <div className="hero-slider-one__content">
                                  <h5 className="hero-slider-one__sub-title">ALHUDA BUTCHERY</h5>
                                    <h2 className="hero-slider-one__title">
                                        The UAE's Favorite <br/>Online Butchery
                                        <span className="hero-slider-one__title__overlay-group">
                                            
                                        </span>
                                    </h2> 
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            
        </div>
    </section> 
    );
}

}

export default Slider;