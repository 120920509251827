 
import React, {Component} from 'react';
import { BrowserRouter, Switch, Link, Route, useNavigate } from 'react-router-dom'; 
 
import axios from 'axios';   
import {api_base_url, base_url} from '../../common/Constants';
import LeftMenu,{AppWithRouter} from './LeftMenu';
 
class ProfileContent extends React.Component{

    constructor(props){
        super(props);

        
        this.state = { 
            user:{},
            show:false
        }
        

    }

    componentDidMount()
    {
        const user_id =  localStorage.getItem('user_id');
        
        if(user_id !== "" && user_id !== null  &&  user_id !== undefined)
        { 
            var data = { user_id : user_id}
            axios.post(api_base_url+"getuser", data).then(response=>{
                this.setState({user:response.data});  
                this.setState({show:true});  
            });
        }
        else
        {
           this.props.navigate('user/login');
        }

    }
  

    render()
    { 
        if (this.state.loading == 1) {
            return <div>Loading...</div>;
          }
    return (
        <section className="profile-page">
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <AppWithRouter selected="profile" />
                    </div>
                    <div className="col-md-9">
                        <div className='user-data' id={!this.state.show ? "dnone" : "dblock"}>
                            <h3>Welcome {this.state.user.firstname} </h3>
                            <div className='user-info'>
                            <p><b>Name: </b>{this.state.user.name}</p>
                            <p><b>Username: </b>{this.state.user.username}</p>
                            <p><b>Email Address: </b>{this.state.user.email}</p>
                            <p><b>Phone Number: </b>{this.state.user.phone}</p>
                             
                            </div>
                        </div>

                        <div className='loading '  id={this.state.show ? "dnone" : "dblock"}> <img src={base_url+"assets/images/loader.gif"} alt="Alhuda" width="150" /></div>
                    
                    </div>
                </div>
            </div>
        </section>
    )
    }

}

export function AppWithNavigate(props){
    const navigate = useNavigate();
    return  (<ProfileContent navigate={navigate} ></ProfileContent>)
}


export default ProfileContent;

