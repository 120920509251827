import React, {Component} from 'react';
import { BrowserRouter, Switch, Link, Route } from 'react-router-dom'; 
import Slider from 'react-slick';
 
import axios from 'axios';  
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

 
class ProductsSlider extends React.Component{

    constructor(props){
        super(props);
 
        this.state = {
            nav1: null,
            nav2: null,
          };
    }
 
    componentDidMount() {
        this.setState({
          nav1: this.slider1,
          nav2: this.slider2,
        });
      }

    render()
    { 
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
          };  
          const thumbSettings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: this.props.image.length,
            slidesToScroll: 5,
            focusOnSelect: true,
            arrows: false,
          };
    return (

        <div>
            
        <Slider
          {...settings}
          asNavFor={this.state.nav2}
          ref={(slider) => (this.slider1 = slider)}
        >
          {this.props.image.map((item, index) => (
            <div key={item}>
             <div className="swiper-slide">
                 <div className="product-details__gallery-top__inner">
                <div className="product-details__gallery-top__image">
                                       <img src={item} alt={this.props.title} />
                                       </div>
                                    </div>
                                    </div>
            </div>
          ))}
        </Slider>
        <div className="product-details__img prod_thumb ">
                            
            
        <Slider
          {...thumbSettings}
          asNavFor={this.state.nav1}
          ref={(slider) => (this.slider2 = slider)}
        >
           {this.props.image.map((item, index) => (
            
            <div key={item}>
                
              <img src={item} alt={this.props.title} />
            
            </div>
          ))}
        </Slider>
        
                        
                        </div>
      </div>
        
    );
}

}

export default ProductsSlider;